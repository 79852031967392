import { UITask } from "../hooks/taskHooks";
import TaskCard from "./TaskCard";

export default function TaskLane(props: { name: string; tasks: UITask[] }) {
  return (
    <div className="w-60 m-3">
      <div className="text-center">{props.name}</div>
      <div>
        {props.tasks
          .filter((task) => task.statusGroup === props.name)
          .map((task) => (
            <TaskCard task={task} />
          ))}
      </div>
    </div>
  );
}
