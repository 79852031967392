import React, { MouseEvent, useEffect, useState } from "react";
import { UITask } from "../hooks/taskHooks";
import { apiRequest } from "../services/baseApi";
import { appendNote, updateTask } from "../services/taskApi";
import { practiceSelected } from "../services/patientapi";
import { useMsal } from "@azure/msal-react";
import { Task } from "../types/fhir";
import { UserIcon } from "@heroicons/react/24/outline";

class DataItem {
  name: string;
  value: string;
  link?: string;

  constructor(name: string, value: string, link?: string) {
    this.name = name;
    this.value = value;
    this.link = link;
  }
}

export default function TaskDashboard(props: { task: UITask; taskId: string }) {
  const task = props.task! as UITask;
  const [status, setStatus] = useState(task?.status);
  const [note, setNote] = useState("");
  const [noteText, setNoteText] = useState("");
  const [notes, setNotes] = useState(task.notes);
  const [lastModified, setLastModified] = useState(task.lastModified);

  const authContext = useMsal();

  useEffect(() => {
    if (!task || !task.id || status === task.status) {
      return;
    }
    apiRequest({
      whenAcquired: (accessToken: string) => {
        if (status) {
          updateTask(accessToken, task.id, status).then((task: Task) => {
            setLastModified(task.lastModified || "");
          });
        }
      },
      authContext: authContext,
      preValidator: practiceSelected,
    }).then((r) => {});
  }, [authContext, status, task, setLastModified]);

  if (!props.task) {
    return <div></div>;
  }

  const items = [
    new DataItem("Priority", task.priority),
    new DataItem("Code", task.code),
    new DataItem("Owner", task.owner),
  ];

  function saveStatus(stat: string) {
    setStatus(stat);
  }

  function addNote(e: MouseEvent<HTMLElement>) {
    e.preventDefault();
    apiRequest({
      whenAcquired: (accessToken: string) => {
        if (note && note.length) {
          appendNote(accessToken, task.id, note).then((result: Task) => {
            setNotes(result.note || []);
            setLastModified(task.lastModified || "");
            setNoteText("");
          });
        }
      },
      authContext: authContext,
      preValidator: practiceSelected,
    }).then((r) => {
      setNote("");
      setNoteText("");
    });
  }

  return (
    <div className="container mx-auto px-5 py-7">
      <form>
        <div className="space-y-12">
          <div className="border-b border-gray-900/10 pb-12">
            <h2 className="text-base font-semibold leading-7 text-gray-900">
              Task
            </h2>
            <p className="mt-1 text-sm leading-6 text-gray-600">
              {task.description}
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="sm:col-span-3">
                <label
                  htmlFor="status"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Status
                </label>
                <div className="mt-2">
                  <select
                    onChange={(e) => saveStatus(e.target.value)}
                    id="status"
                    name="status"
                    defaultValue={status}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:max-w-xs sm:text-sm sm:leading-6"
                  >
                    <option value="requested">Requested</option>
                    <option value="received">Received</option>
                    <option value="accepted">Accepted</option>
                    <option value="rejected">Rejected</option>
                    <option value="in-progress">In-Progress</option>
                    <option value="on-hold">On-Hold</option>
                    <option value="failed">Failed</option>
                    <option value="completed">Completed</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              {items.map((item) => (
                <div key={item.name} className="sm:col-span-4">
                  <h3>{item.name}</h3>
                  <p className="mt-3 text-sm leading-6 text-gray-600">
                    {item.value}
                  </p>
                </div>
              ))}
              <div className="sm:col-span-4">
                <h3>Last Modified</h3>
                <p className="mt-3 text-sm leading-6 text-gray-600">
                  <time dateTime={lastModified}>{lastModified}</time>
                </p>
              </div>
            </div>
            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <label
                  htmlFor="about"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Add Note
                </label>
                <div className="mt-2">
                  <textarea
                    onChange={(e) => {
                      setNoteText(e.target.value);
                    }}
                    onBlur={(e) => setNote(e.target.value)}
                    id="about"
                    name="about"
                    rows={3}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    value={noteText}
                  />
                </div>
                <div className="mt-6 flex items-center  gap-x-6">
                  <button
                    onClick={(e) => addNote(e)}
                    className="rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                  >
                    Add
                  </button>
                </div>
              </div>

              <div className="col-span-full">
                <ul className="divide-y divide-gray-100">
                  {notes.map((annotation) => (
                    <li
                      key={
                        (annotation.authorString || "") +
                        annotation.time +
                        annotation.text
                      }
                      className="flex justify-between gap-x-6 py-5"
                    >
                      <div className="flex min-w-0 gap-x-4">
                        <UserIcon
                          className="h-12 w-12 flex-none rounded-full bg-gray-50"
                          aria-hidden="true"
                        />

                        <div className="min-w-0 flex-auto">
                          <p className="text-sm font-semibold leading-6 text-gray-900">
                            {annotation.authorString}
                          </p>
                          <p>{annotation.text}</p>
                        </div>
                      </div>
                      <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
                        <p className="text-sm leading-6 text-gray-900">
                          <time dateTime={annotation.time}>
                            {annotation.time
                              ? new Date(annotation.time).toLocaleDateString() +
                                " " +
                                new Date(annotation.time).toLocaleTimeString()
                              : ""}
                          </time>
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          <button
            type="button"
            className="text-sm font-semibold leading-6 text-gray-900"
          >
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
}
