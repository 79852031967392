import { Diagnosis, Patient } from "../types/models";
import React, { useEffect, useState } from "react";
import BubbleChart from "./BubbleChart";

export default function Diagnoses(props: {
  patient: Patient;
  series: Diagnosis[];
}) {
  const [diagnosisCategories, setDiagnosisCategories] = useState(
    [] as string[]
  );
  const [filteredSeries, setFilteredSeries] = useState(props.series);
  const [selectedCategory, setSelectedCategory] = useState("All");

  useEffect(() => {
    const categories = new Set();
    props.series.forEach((diagnosis) => {
      categories.add(diagnosis.category);
    });
    const categoryList = Array.from(categories.keys()) as string[];
    categoryList.sort();
    setDiagnosisCategories(categoryList);
    setFilteredSeries(props.series);
  }, [props.series]);
  const sortingFunc = (a: Diagnosis, b: Diagnosis) =>
    a.description.localeCompare(b.description);

  useEffect(() => {
    if ("All" === selectedCategory) {
      setFilteredSeries(props.series.sort(sortingFunc));
    } else {
      setFilteredSeries(
        props.series
          .filter((series) => series.category === selectedCategory)
          .sort(sortingFunc)
      );
    }
  }, [selectedCategory, props.series]);

  const rowDescription = (row: Diagnosis): string => {
    return row.description;
  };

  return (
    <>
      <div className="print:hidden mb-10">
        <select
          id="diagnosisCategorySelect"
          name="diagnosisCategorySelection"
          onInput={(e) => setSelectedCategory((e.target as any).value)}
          className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-gray-900 ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
        >
          <option value="All" key="diagnosis-category-all-0">
            All Categories
          </option>
          {diagnosisCategories.map((category: string, index: number) => (
            <option
              value={category}
              key={`diagnosis-category-${category}-${index + 1}`}
            >
              {category}
            </option>
          ))}
        </select>
      </div>

      <BubbleChart
        name={"Diagnoses"}
        patient={props.patient}
        rowDescription={rowDescription}
        rows={filteredSeries}
      />
    </>
  );
}
