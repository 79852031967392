import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { usePatientDashboard } from "../hooks/patientHooks";
import PatientDashboard from "../components/PatientDashboard";
import Loading from "../components/Loading";

export default function PatientDetail() {
  const { patientId } = useParams();
  const [loading, setLoading] = useState(true);
  const patientDashboard = usePatientDashboard(patientId, setLoading);

  return (
    <>
      {loading ? (
        <Loading loading={loading} />
      ) : (
        <PatientDashboard
          patientId={patientId}
          patientDashboard={patientDashboard}
        />
      )}
    </>
  );
}
