import { DataSeries, Patient } from "../types/models";
import {
  ResponsiveContainer,
  Scatter,
  ScatterChart,
  Tooltip,
  XAxis,
  YAxis,
  ZAxis,
} from "recharts";
import React from "react";

const dotColors = [
  "#CCCC00",
  "#CC6600",
  "#33FF00",
  "#CC0000",
  "#66FF00",
  "#330033",
  "#669900",
  "#00CCFF",
  "#FFCC33",
  "#00CCCC",
];

export default function BubbleChart(props: {
  patient: Patient;
  rowDescription: (d: any) => string;
  name: string;
  rows: DataSeries[];
}) {
  const range = [0, 225];
  const parseDomain = (data: any) => [
    0,
    Math.max(
      Math.max.apply(
        null,
        data.map((entry: any) => entry.value)
      )
    ),
  ];

  const renderTooltip = (props: any) => {
    const { active, payload } = props;

    if (active && payload && payload.length) {
      const data = payload[0] && payload[0].payload;
      return (
        <div
          style={{
            backgroundColor: "#fff",
            border: "1px solid #999",
            margin: 0,
            padding: 10,
          }}
        >
          <p>{data.name}</p>
        </div>
      );
    }

    return null;
  };

  return (
    <div>
      <ResponsiveContainer width="100%" height="auto">
        <table className={"table-fixed"}>
          <tbody>
            {props.rows.map((row: DataSeries, index: number) => (
              <tr key={`${props.name}-${index}`}>
                <td width={400} className="text-gray-900 dark:text-white">
                  {props.rowDescription(row)}
                </td>
                <td>
                  <ResponsiveContainer width="100%" height={60}>
                    <ScatterChart
                      syncId="selectedDate"
                      syncMethod="value"
                      width={900}
                      height={60}
                      margin={{
                        top: 10,
                        right: 0,
                        bottom: 0,
                        left: 0,
                      }}
                    >
                      <XAxis
                        type="category"
                        dataKey="name"
                        name="Date"
                        interval="preserveStartEnd"
                        scale={"auto"}
                        tick={{ fontSize: 12 }}
                        tickLine={{ transform: "translate(0, -6)" }}
                      />
                      <YAxis
                        type="number"
                        dataKey="index"
                        height={10}
                        width={10}
                        tick={false}
                        tickLine={false}
                        axisLine={true}
                      />
                      <ZAxis
                        type="number"
                        dataKey="value"
                        domain={parseDomain(row.data)}
                        range={range}
                      />
                      <Scatter
                        data={row.data}
                        fill={dotColors[index % dotColors.length]}
                      />
                      <Tooltip content={renderTooltip} />
                    </ScatterChart>
                  </ResponsiveContainer>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ResponsiveContainer>
    </div>
  );
}
