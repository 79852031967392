import { UITask } from "../hooks/taskHooks";
import TaskLane from "./TaskLane";

export default function TaskSwimlane(props: { tasks: UITask[] }) {
  return (
    <div className="sticky top-0 z-40 flex min-h-full gap-x-6 px-4 py-4 shadow-sm sm:px-6 text-gray-900 dark:text-white">
      <TaskLane name="TO-DO" tasks={props.tasks} />
      <TaskLane name="WAITING" tasks={props.tasks} />
      <TaskLane name="IN-PROGRESS" tasks={props.tasks} />
      <TaskLane name="DONE" tasks={props.tasks} />
    </div>
  );
}
