import { CombinedEgfrs } from "../types/models";
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

export default function EGFRGraph(props: { data: CombinedEgfrs }) {
  const reversed =
    props.data.egfrs.length &&
    props.data.calculated_egfrs.length &&
    props.data.egfrs[0].d > props.data.calculated_egfrs[0].d;
  const dataValue = reversed
    ? {
        series1: props.data.calculated_egfrs,
        series1Name: "calculated",
        series2: props.data.egfrs,
        series2Name: "predicted",
      }
    : {
        series1: props.data.egfrs,
        series1Name: "predicted",
        series2: props.data.calculated_egfrs,
        series2Name: "calculated",
      };
  const maxReducer = (
    previousValue: number,
    currentValue: number,
    currentIndex: number,
    array: number[]
  ) => Math.max(currentValue, previousValue);
  const maxValue1 = props.data.calculated_egfrs
    .map((dv) => dv.v)
    .reduce(maxReducer);
  const maxValue2 = props.data.egfrs.map((dv) => dv.v).reduce(maxReducer);
  const maxValue = Math.round(Math.max(maxValue1, maxValue2)) + 10;

  return (
    <div className="mt-5">
      <div className="relative">
        <LazyLoadImage
          src={`/icons/svg/outline/body/kidneys.svg`}
          width="32"
          height="32"
          alt={`kidneys outline icon`}
          effect="opacity"
          className="absolute left-6 inset-y"
        />
        <h2 className="screen:font-large screen:font-bold screen:text-xl print:font-medium text-gray-500 dark:text-white pl-10 pb-2 ml-5">
          EGFR
        </h2>
      </div>
      <ResponsiveContainer width="95%" height={400} debounce={1}>
        <LineChart
          width={200}
          height={400}
          syncId="selectedDate"
          syncMethod="value"
        >
          <Legend wrapperStyle={{ bottom: 0 }} />
          <CartesianGrid stroke="#aaa" />
          <Tooltip />
          <XAxis dataKey="d" allowDuplicatedCategory={false} tickMargin={10} />
          <YAxis dataKey="v" domain={[0, maxValue]} />
          <Line
            type="linear"
            dataKey="v"
            strokeDasharray="5 5"
            stroke="#880000"
            strokeWidth="3"
            data={dataValue.series1}
            name={dataValue.series1Name}
            key={dataValue.series1Name}
          />
          <Line
            type="linear"
            dataKey="v"
            stroke="#8884d8"
            strokeWidth="3"
            data={dataValue.series2}
            name={dataValue.series2Name}
            key={dataValue.series2Name}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
}
