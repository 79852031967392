import { UITask } from "../hooks/taskHooks";
import { useNavigate } from "react-router-dom";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export default function TaskCard(props: { task: UITask }) {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(`/tasks/${props.task.id}`)}
      className="overflow-hidden cursor-pointer rounded-lg bg-gray-50 shadow  min-w-xs max-w-xs border-2 border-gray-900 mt-5 mb-5 text-black"
    >
      <div className="bg-white py-5 sm:p-6">
        <div className="mb-5">{props.task.description}</div>
        {props.task.dueDate ? (
          <div className="mb-5">Due: {props.task.dueDate}</div>
        ) : (
          ""
        )}
        {props.task.priority ? (
          <div>
            <span
              className={classNames(
                props.task.priorityTextColor,
                props.task.priorityBackgroundColor,
                "inline-flex items-center rounded-full  px-2 py-1 text-xs font-medium"
              )}
            >
              {props.task.priority}
            </span>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
