import { useTasks } from "../hooks/taskHooks";
import TaskSwimlane from "../components/TaskSwimlane";
import React, { useState } from "react";
import Loading from "../components/Loading";

export default function Tasks() {
  const [loading, setLoading] = useState(true);
  const tasks = useTasks(setLoading);
  if (!tasks) {
    return <div></div>;
  }
  return (
    <>
      {!loading ? (
        <div className="px-4 sm:px-6 lg:px-8 mt-6">
          <TaskSwimlane tasks={tasks} />
        </div>
      ) : (
        <Loading loading={loading} />
      )}
    </>
  );
}
