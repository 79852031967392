import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { usePatients } from "../hooks/patientHooks";
import PatientList from "../components/PatientList";
import Loading from "../components/Loading";
import {
  ColumnFiltersState,
  PaginationState,
  SortingState,
} from "@tanstack/react-table";
import { usePlans } from "../hooks/planHooks";

export default function Patients() {
  const [practiceName, , setSearchContext] = useOutletContext<any[]>();
  const [loading, setLoading] = useState(true);
  const [sorting, setSorting] = useState<SortingState>([
    { id: "current_risk_score", desc: true },
  ]);
  const [columnFilters, setColumnFilters] = React.useState<ColumnFiltersState>(
    []
  );

  const [pagination, setPagination] = useState<PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [rowCount, setRowCount] = useState(0);
  const patients = usePatients(
    practiceName,
    setLoading,
    pagination,
    setRowCount,
    columnFilters,
    sorting
  );
  const plansForPractice = usePlans(practiceName);

  return (
    <>
      {!loading ? (
        <PatientList
          patients={patients}
          plansForPractice={plansForPractice}
          setSorting={setSorting}
          sorting={sorting}
          pagination={pagination}
          setPagination={setPagination}
          rowCount={rowCount}
          practiceName={practiceName}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
          setSearchContext={setSearchContext}
        />
      ) : (
        <Loading loading={loading} />
      )}
    </>
  );
}
