import { useMsal } from "@azure/msal-react";
import { useContext, useEffect, useState } from "react";
import {
  findEGFRForMrn,
  findLabDataByLookupCode,
  findLabNames,
  findLabSummaries,
  findPatientByMrn,
  findPatientDashboardByMrn,
  findPatients,
  findPatientStatsByMrn,
  findVitals,
  practiceSelected,
} from "../services/patientapi";
import {
  CompositePatient,
  DataSeries,
  EgfrSeries,
  Lab,
  LabResult,
  Patient,
  Vitals,
} from "../types/models";
import { apiRequest } from "../services/baseApi";
import {
  ColumnFiltersState,
  ColumnSort,
  PaginationState,
} from "@tanstack/react-table";
import { SearchContext } from "../components/AppLayout";

export interface LabData {
  labCategories: string[];
  labTypes: Lab[];
}

export function useSeriesData(patientId: string, statName: string) {
  const authContext = useMsal();
  const [rows, setRows] = useState([] as DataSeries[]);

  useEffect(() => {
    apiRequest({
      whenAcquired: (accessToken: string) => {
        findPatientStatsByMrn(patientId, accessToken, statName).then((data) => {
          setRows(data);
        });
      },
      authContext: authContext,
      preValidator: practiceSelected,
    }).then(() => {});
  }, [authContext, patientId, statName]);
  return rows;
}

export function useLabResults(
  patientId: string,
  selectedLab: Lab | null
): LabResult[] {
  const authContext = useMsal();
  const [labResults, setLabResults] = useState([] as LabResult[]);

  useEffect(() => {
    apiRequest({
      whenAcquired: (accessToken: string) => {
        if (selectedLab) {
          findLabDataByLookupCode(
            patientId,
            selectedLab.lookup_code,
            accessToken
          ).then((data) => {
            setLabResults(data);
          });
          return;
        }
        setLabResults([]);
      },
      authContext: authContext,
      preValidator: practiceSelected,
    }).then((r) => {});
  }, [authContext, selectedLab, patientId]);

  return labResults;
}

export function useLabData(patientId: string): LabData {
  const [labCategories, setLabCategories] = useState([] as string[]);
  const [labTypes, setLabTypes] = useState([] as Lab[]);
  const authContext = useMsal();

  useEffect(() => {
    apiRequest({
      whenAcquired: (accessToken: string) => {
        findLabNames(patientId!, accessToken).then((labNames: Lab[]) => {
          const categories = new Set();
          labNames.forEach((lab: Lab) => {
            categories.add(lab.category);
          });
          setLabCategories(Array.from(categories.keys()) as string[]);
          setLabTypes(labNames);
        });
      },
      authContext: authContext,
      preValidator: practiceSelected,
    });
  }, [authContext, patientId]);

  return { labCategories, labTypes };
}

export function usePatientDashboard(
  patientId: string | undefined,
  setLoading: (value: ((prevState: boolean) => boolean) | boolean) => void
): CompositePatient {
  if (!patientId) {
    setLoading(false);
    throw new Error("Patient ID not specified");
  }
  const authContext = useMsal();
  const [patient, setPatient]: any = useState();

  useEffect(() => {
    apiRequest({
      whenAcquired: (accessToken: string) => {
        findPatientDashboardByMrn(patientId!, accessToken).then(
          (patientDashboard) => {
            setLoading(false);
            setPatient(patientDashboard);
          }
        );
      },
      authContext: authContext,
      preValidator: practiceSelected,
    });
  }, [authContext, patientId, setLoading]);

  return patient;
}

export function usePatient(
  patientId: string | undefined,
  setLoading: (value: ((prevState: boolean) => boolean) | boolean) => void
): Patient {
  if (!patientId) {
    setLoading(false);
    throw new Error("Patient ID not specified");
  }
  const authContext = useMsal();
  const [patient, setPatient]: any = useState();

  useEffect(() => {
    apiRequest({
      whenAcquired: (accessToken: string) => {
        findPatientByMrn(patientId!, accessToken).then((patient) => {
          setLoading(false);
          setPatient(patient);
        });
      },
      authContext: authContext,
      preValidator: practiceSelected,
    });
  }, [authContext, patientId, setLoading]);

  return patient;
}

export function useEgfrSeries(patientId: string): EgfrSeries {
  const authContext = useMsal();
  const [egfr, setEGFR] = useState({
    series1: [],
    series1Name: "",
    series2: [],
    series2Name: "",
  } as EgfrSeries);
  useEffect(() => {
    apiRequest({
      whenAcquired: (accessToken: string) => {
        findEGFRForMrn(patientId, accessToken).then((data) => {
          const reversed =
            data.egfrs.length &&
            data.calculated_egfrs.length &&
            data.egfrs[0].d > data.calculated_egfrs[0].d;
          const dataValue = reversed
            ? {
                series1: data.calculated_egfrs,
                series1Name: "calculated",
                series2: data.egfrs,
                series2Name: "predicted",
              }
            : {
                series1: data.egfrs,
                series1Name: "predicted",
                series2: data.calculated_egfrs,
                series2Name: "calculated",
              };
          setEGFR(dataValue);
        });
      },
      authContext: authContext,
      preValidator: practiceSelected,
    }).then((r) => {});
  }, [authContext, patientId]);
  return egfr;
}

export function useVitals(patientId: string) {
  const authContext = useMsal();
  const [vitals, setVitals] = useState<Vitals>({
    systolic: [],
    diastolic: [],
    weight: [],
    weight_unit: "",
  });
  const [weightUnit, setWeightUnit] = useState("");

  useEffect(() => {
    apiRequest({
      whenAcquired: (accessToken: string) => {
        findVitals(patientId, accessToken).then((data: Vitals) => {
          setVitals(data);
          setWeightUnit(data.weight_unit ? ` in ${data.weight_unit}` : "");
        });
      },
      authContext: authContext,
      preValidator: practiceSelected,
    }).then((r) => {});
  }, [authContext, patientId]);

  return { vitals, weightUnit };
}

export function useLabSummaries(patientId: string) {
  const authContext = useMsal();
  const [summary, setSummary] = useState({
    summaries: [],
    encounter_dates: [],
  });

  useEffect(() => {
    apiRequest({
      whenAcquired: (accessToken: string) => {
        const bgColors = [
          "bg-blue-300 dark:bg-blue-900",
          "bg-emerald-300 dark:bg-emerald-900",
          "bg-violet-300 dark:bg-violet-900",
          "bg-sky-300 dark:bg-sky-900",
          "bg-lime-300 dark:bg-lime-900",
          "bg-grey-300 dark:bg-grey-900",
          "bg-teal-300 dark:bg-teal-900",
        ];

        findLabSummaries(patientId, accessToken).then((data) => {
          let lastCategory: string | null = null;
          let categoryIndex = -1;
          data.summaries.forEach((summary: any) => {
            if (summary.category !== lastCategory) {
              categoryIndex++;
              lastCategory = summary.category;
            }
            if (categoryIndex >= bgColors.length) {
              categoryIndex = 0;
            }
            summary.color = bgColors[categoryIndex];
          });
          setSummary(data);
        });
      },
      authContext: authContext,
      preValidator: practiceSelected,
    }).then((r) => {});
  }, [authContext, patientId]);
  return summary;
}

export function usePatients(
  practiceName: string,
  setLoading: (value: ((prevState: boolean) => boolean) | boolean) => void,
  pagination: PaginationState,
  setRowCount: (value: ((prevState: number) => number) | number) => void,
  columnFilters: ColumnFiltersState,
  sortBy?: ColumnSort[]
) {
  const authContext = useMsal();
  const searchContext = useContext(SearchContext);
  const [getPatients, setPatients] = useState([] as Patient[]);
  const filterBy = columnFilters
    .map((columnFilter) => {
      return columnFilter.id;
    })
    .join(",");
  const filterValues = columnFilters
    .map((columnFilter) => {
      return columnFilter.value;
    })
    .join(",");

  useEffect(() => {
    apiRequest({
      whenAcquired: (accessToken: string) => {
        const sort = sortBy && sortBy.length > 0 ? sortBy[0].id : null;
        const direction: string | null =
          sortBy && sortBy.length > 0
            ? sortBy[0].desc
              ? "desc"
              : "asc"
            : null;

        findPatients(
          accessToken,
          searchContext,
          sort,
          direction,
          pagination.pageIndex,
          pagination.pageSize,
          filterBy,
          filterValues
        ).then((users) => {
          setLoading(false);
          setPatients(users.content);
          setRowCount(users.total_count);
        });
      },
      authContext: authContext,
      preValidator: practiceSelected,
    }).then((r) => {});
  }, [
    authContext,
    searchContext,
    practiceName,
    setLoading,
    sortBy,
    pagination,
    setRowCount,
    filterBy,
    filterValues,
  ]);
  return getPatients;
}
